import React from 'react'
import tw, { styled } from 'twin.macro'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Stripes from '@elements/Stripes'
import parse from 'html-react-parser'
import LinkButton from '@elements/LinkButton'
import Modal from '@elements/form/FormModal'
import { useBreakpoint } from '@hooks/useBreakpoint'

const Section = tw.section`relative`
const ImageWrap = tw.div`relative flex w-full h-[450px] lg:h-[800px]`
const Content = tw.div`relative z-20 px-offset-xs w-full max-w-screen-2xl mx-auto md:mr-0 md:w-1/2 md:ml-auto mb-0 lg:mb-6 xl:mb-20 pt-spacingXl-lg`
const Overline = tw.p`mb-4 text-copyXl uppercase tracking-1 pl-1`
const Headline = styled.h1`
	${tw`w-full mb-8`}
`
const Text = tw.p`sm:w-1/2 md:w-9/12`

const WpAcfHeadersecondaryBlock = ({ data, overline = null, locale }) => {
	const module = data.modulesHeaderSecondary || data.headerFields
	const { isMobile } = useBreakpoint()
	const [modalOpen, setModalOpen] = React.useState(false)
	const toggleModal = () => setModalOpen((prev) => !prev)

	if (!module.imagePrimary?.localFile) return ''
	return (
		module.imagePrimary?.localFile && (
			<Section className='emf-pb-spacing'>
				<div className='wrap'>
					<ImageWrap>
						<GatsbyImage
							image={getImage(isMobile && module.imagePrimaryMobile?.localFile ? module.imagePrimaryMobile?.localFile : module.imagePrimary.localFile)}
							imgClassName='object-left'
							className='object-cover w-full h-full'
							alt={isMobile && module.imagePrimaryMobile?.altText ? module.imagePrimaryMobile?.altText : module.imagePrimary?.altText || ''}
						/>
						<Stripes className='top-full rot-x-180 md:rot-x-0 md:!rotate-180' />
					</ImageWrap>
					<Content>
						{overline && <Overline>{overline}</Overline>}
						{module.headline && <Headline className='headline-h2'>{parse(module.headline)}</Headline>}
						{module.text && <Text className='text-base sm:text-copyXl'>{parse(module.text)}</Text>}
						{module.button && !module.formButton && <LinkButton className='z-10 mt-8 font-futuraBook' target={module.button.target} href={module.button.url} label={module.button.title} />}
						{module.button && module.formButton && (
							<LinkButton
								onClick={(event) => {
									event.preventDefault()
									!!module.formId && toggleModal()
								}}
								label={module.button.title}
								className='z-10 mt-8 cursor-pointer font-futuraBook'
							/>
						)}
					</Content>
				</div>
				{!!module.formId && <Modal modalOpen={modalOpen} handleClose={toggleModal} formId={module.formId} locale={locale} />}
			</Section>
		)
	)
}
export default WpAcfHeadersecondaryBlock
